import React from 'react';
import { useEffect, useState, useRef } from 'react';
import {
  Backdrop,
  Box,
  Button,
  Drawer,
  Fade,
  Grid,
  IconButton,
  Link,
  Modal,
  Typography,
} from '@material-ui/core';
import { makeStyles,   useTheme } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
//import GamePageLinks from '../components/game-page-links';
import CrabList from '../components/index/crab-list';
import ImageCard from '../components/image-card';
import Layout from '../components/layout';
import NewsletterSignup from '../components/newsletter-signup';
import YoutubePlayer from '../components/youtube-player';
// Images
import BoxAges from '../assets/crabs-in-a-bucket/box-ages.png';
import BoxPlayers from '../assets/crabs-in-a-bucket/box-players.png';
import BoxRulesP1 from '../assets/crabs-in-a-bucket/box-rules-1.png';
import BoxRulesP2 from '../assets/crabs-in-a-bucket/box-rules-2.png';
import BoxTime from '../assets/crabs-in-a-bucket/box-time.png';
import CrabsBoxart from '../assets/crabs-in-a-bucket/bannerupdated-2.png';
import CrabsWordMark from '../assets/crabs-in-a-bucket/crab-word-mark.png';
import PromoCover from '../assets/crabs-in-a-bucket/cover/21.png'
import PromoPower from '../assets/crabs-in-a-bucket/boardgamescrowdfunding_vis-part3.png';
import PromoRuler from '../assets/crabs-in-a-bucket/boardgamescrowdfunding_vis-part2.png';

const styling = (theme) => ({
  body: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  boxIcons: {
    maxHeight: 64,
    maxWidth: 64,
  },
  boxIconsContainer: {
    margin: 'auto',
    maxWidth: '100%',
    [theme.breakpoints.up('md')]: {
      maxWidth: 550,
    },
  },
  description: {
    maxWidth: 900,
    padding: '8px 16px',
    textAlign: 'justify',
  },
  drawerRules: {
    backgroundColor: theme.palette.common.white,
  },
  hero: {
    padding: 0,
    position: 'relative',
  },
  heroImg: {
    width: '100%',
  },
  modal: {
    alignItems: 'center',
    background: theme.palette.primary.light,
    boxShadow: '0 4px 12px rgb(0 0 0 / 0.5)',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    padding: 8,
    paddingBottom: 16,
    margin: '20%',
    maxWidth: 300,
    '&:focus': {
      outline: 'none',
    },
  },
  modalButtonClose: {
    alignSelf: 'flex-end',
    right: 16,
    top: -12,
  },
  modalImage: {
    maxWidth: [300, '!important'],
  },
  modalImageRules: {
    width: '100%',
  },
  modalImageRulesContainer: {
    height: '100%',
    overflow: 'auto',
    whiteSpace: 'nowrap',
  },
  modalText: {
    color: theme.palette.primary.contrastText,
    marginBottom: 16,
    textAlign: 'center',
  },
  placeholderModalImg: {
    background: theme.palette.common.white,
    height: 96,
    marginBottom: 16,
    width: 96,
  },
  promoCover: {
    paddingTop: 32,
  },
  promoImage: {
    padding: '32px 16px 0px 16px',
  },
  rulesButton: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: 16,
    margin: 'auto',
    maxWidth: 300,
    textAlign: 'center',
  },
  tagline: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '32px 0',
    margin: 'auto',
    maxWidth: 450,
    textAlign: 'center',
  },
  title: {
    color: theme.palette.common.white,
    paddingLeft: 12,
  },
  titleBanner: {
    background: 'linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 95%, rgba(255,255,255,0) 100%)',
    bottom: 0,
    left: 0,
    position: 'absolute',
    width: '100%',
  },
  titleBannerTopShadow: {
    background: 'linear-gradient(180deg, rgba(0,0,0,0.5) 0%, rgba(255,255,255,0) 50%)',
    height: 50,
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  ytContainer: {
    padding: 16,
  },
  ytVideo: {
    margin: 'auto',
    maxWidth: 650,
    minHeight: 350,
    [theme.breakpoints.up('lg')]: {
      maxWidth: 1000,
      minHeight: 600,
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: 1300,
      minHeight: 700,
    },
  }
});

const Homepage = () => {
  // Styling/Theming
  const theme = useTheme();
  const classes = makeStyles(styling)();

  // States/Refs
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRulesOpen, setIsRulesOpen] = useState(false);
  const hasScrolled = useRef(false);
  const scrollPosition = useRef(0);
  let scrollPositionStart = 0;

  // Effects
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    scrollPositionStart = window.scrollY;

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollPosition.current]);

  // Handlers
  const handleModalClose = () => {
    setIsModalOpen(false);
    hasScrolled.current = true; 
    setNewsletterCookie();
  };

  const handleRulesClose = () => {
    setIsRulesOpen(false);
  };

  const handleRulesOpen = () => {
    setIsRulesOpen(true);
  };

  // Cookies
  const setNewsletterCookie = () => {
    localStorage.setItem('newsletterSeen', 1)
  }

  const handleScroll = () => {
    scrollPosition.current = window.scrollY;
    if ((scrollPosition.current > 500 + scrollPositionStart
        || scrollPosition.current < scrollPositionStart - 500)
        && !hasScrolled.current
        && !localStorage.getItem('newsletterSeen')) {
      setIsModalOpen(true);
      hasScrolled.current = true; 
    }
  };
  
  return (
    <Layout>
      <Modal
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        closeAfterTransition
        onClose={handleModalClose}
        open={isModalOpen}
        style={{ display:'flex', alignItems:'center', justifyContent:'center' }}
      >
        <Fade in={isModalOpen}>
          <Box className={classes.modal}>
            <Grid container direction='row'>
              <Grid item xs={1}></Grid>
              <Grid item xs={10}>
                <ImageCard classes={{ image: classes.modalImage }} src={CrabsWordMark} />
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  aria-label="close navigation menu"
                  className={classes.modalButtonClose}
                  color="inherit"
                  onClick={handleModalClose}
                >
                  <CloseIcon style={{color: theme.palette.primary.contrastText}}/>
                </IconButton>
              </Grid>
            </Grid>
            <Typography className={classes.modalText}>
              Subscribe to our Newsletter for free stuff!
            </Typography>
            <NewsletterSignup cookie='newsletterSeen' />
          </Box>
        </Fade>
      </Modal>
      <Link
        href="https://shop.bluerondogames.com/"
        target='_blank'
        rel='noopener'
      >
        <ImageCard
          classes={{
            root: classes.hero,
            image: classes.heroImg,
          }}
          src={CrabsBoxart}
        />
      </Link>

      <YoutubePlayer 
        classes={{
          root: classes.ytContainer,
          video: classes.ytVideo,
        }}
        src="https://www.youtube.com/embed/KJ3Wx40yxIo"
      />

      <Drawer
        anchor="bottom"
        classes={{
          paper: classes.drawerRules,
        }}
        open={isRulesOpen}
      >
        <IconButton
          aria-label="close navigation menu"
          className={classes.modalButtonClose}
          color="inherit"
          onClick={handleRulesClose}
        >
          <CloseIcon style={{color: theme.palette.common.black}}/>
        </IconButton>
        <Box className={classes.modalImageRulesContainer}>
          <ImageCard 
            classes={{
              image: classes.modalImageRules,
            }}
            src={BoxRulesP1}
          />
          <ImageCard 
            classes={{
              image: classes.modalImageRules,
            }}
            src={BoxRulesP2}
          />
        </Box>
      </Drawer>
      <Box className={classes.tagline}>
        <Typography variant="h4">Spite outlives the shellfish!</Typography>
      </Box>

      <Box className={classes.boxIconsContainer}>
        <Grid container justifyContent="space-evenly">
          <Grid item>
            <ImageCard classes={{root: classes.boxIcons}} src={BoxAges} />
          </Grid>
          <Grid item>
            <ImageCard classes={{root: classes.boxIcons}} src={BoxPlayers} />
          </Grid>
          <Grid item>
            <ImageCard classes={{root: classes.boxIcons}} src={BoxTime} />
          </Grid>
        </Grid>
      </Box>

      <Grid container justifyContent="space-evenly">
        <Grid item xs={12} md={6}>
          <ImageCard classes={{root: classes.promoImage}} src={PromoRuler} />
        </Grid>
        <Grid item xs={12} md={6}>
          <ImageCard classes={{root: classes.promoImage}} src={PromoPower} />
        </Grid>
      </Grid>
      
      <YoutubePlayer 
        classes={{
          root: classes.ytContainer,
          video: classes.ytVideo,
        }}
        src="https://www.youtube.com/embed/nG8azBtj07Q"
      />
      <Box className={classes.rulesButton}>
        <Button
          color="primary"
          onClick={handleRulesOpen}
          variant="contained"
        >
          Check out the rules
        </Button>
      </Box>


      <ImageCard classes={{root: classes.promoCover}} src={PromoCover} />

      <CrabList />
    </Layout>
  );
}

export default Homepage
