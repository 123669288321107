import React from 'react';
import {
  Card,
  CardMedia,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const iframeDefaultProps = {
  title: "YouTube video player",
  frameBorder: "0",
  allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
  allowFullScreen: true,
};

const YoutubePlayer = ({
  cardProps,
  children,
  className,
  classes,
  elevation,
  iframeProps,
  square,
  src,
}) => {
  const iframe = {...iframeDefaultProps, ...iframeProps};

  return (
    <Card
      className={className || classes?.root}
      elevation={elevation}
      square={square}
      style={{ backgroundColor: 'transparent' }}
      {...cardProps}
    >
      <CardMedia
        className={classes?.video}
        component="iframe"
        src={src}
        {...iframe}
      />
      {children}
    </Card>
)};

YoutubePlayer.propTypes = {
  cardProps: PropTypes.any,
  children: PropTypes.any,
  className: PropTypes.string,
  classes: PropTypes.objectOf(String),
  elevation: PropTypes.number,
  iframeProps: PropTypes.any,
  props: PropTypes.any,
  square: PropTypes.bool,
  src: PropTypes.string.isRequired,
}

YoutubePlayer.defaultProps = {
  elevation: 0,
  square: true,
}

export default YoutubePlayer;
