import React from 'react';
import { useStaticQuery, graphql, Link } from "gatsby";
import {
  Box,
  Card,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ImageCard from '../image-card';
import { PROMO_ID_MAX } from '../../constants';

const styling = (theme) => ({
  image: {
    position: 'relative',
    textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000',
    '&:hover > img': {
        transition: '0.3s',
        filter: 'blur(5px)',
    },
  },
  names: {
    [theme.breakpoints.up('md')]: {
      padding: 16,
      userSelect: 'none',
    },
  },
  namesTBA: {
    [theme.breakpoints.up('md')]: {
      padding: 16,
      textAlign: 'center',
    },
  },
  namesOverlay: {
    alignItems: 'center',
    filter: 'none',
    color: theme.palette.primary.contrastText,
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    left: '0%',
    opacity: 0,
    position: 'absolute',
    textAlign: 'center',
    top: '0%',
    transition: '0.3s',
    width: '100%',
    '&:hover': {
      opacity: 1,
    },
  },
  question: {
    paddingBottom: '25%',
    paddingTop: '25%',
    textAlign: 'center',
  },
  root: {
    padding: 16,
  },
});

const CrabList = () => {
  // Styling
  const theme = useTheme();
  const classes = makeStyles(styling)();

  // Hooks
  const isDesktop = useMediaQuery(theme.breakpoints.up('md')); // md = 960px
  const data = useStaticQuery(query);
  const imgList = data.allFile.nodes;
  const cardImgList = data.allCardImg.nodes;
  const crabList = data.allCrabListJson.nodes;

  // Get img url
  const GetPublicURL = (crab) => {
    const found = imgList.find((img) => (img.name === crab.name));
    if (!found) return null;
    return found.publicURL;
  };

  // Get card img url
  const GetPublicCardURL = (crab) => {
    const found = cardImgList.find((img) => (img.name === crab.name))
    if (!found) return null;
    return found.publicURL;
  };

  return (
    <Card
      className={classes.root}
      elevation={0}
      square
      style={{ backgroundColor: 'transparent' }}
    >
    <Box sx={{ flexGrow: 1 }}>
      <Grid container direction='row' spacing={2}>
        {crabList.map((crab) => {
          if (crab.jsonId > PROMO_ID_MAX) {
            return <Box key={crab.jsonId}></Box>;
          }

          return (
            <Grid alignItems="center" justifyContent='center' container key={crab.jsonId} item xs={6} md={3}>
              { isDesktop ?
                <Link to={`/games/crabs/${crab.name}`}>
                  <Grid item xs={5} md={12}>
                    <ImageCard className={classes.image} src={GetPublicURL(crab)}>
                      <Box className={classes.namesOverlay}>
                        <Typography className={classes.names} variant="h4">
                          {crab.fullname}
                        </Typography>
                      </Box>
                    </ImageCard>
                  </Grid>
                </Link>
                :
                <Grid item>
                  <Link to={`/games/crabs/${crab.name}`}>
                    <ImageCard src={GetPublicCardURL(crab)} />
                  </Link>
                </Grid>
              }
            </Grid>
          )})}

        <Grid alignItems="center" container>
          { isDesktop ?
            <Grid item md={12}>
              <Typography className={classes.namesTBA} variant="h4">
                More to be announced...
              </Typography>
            </Grid>
            :
            <>
              <Grid item xs={5}>
                <Typography className={classes.question} variant="h2">
                  ?
                </Typography>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={6}>
                <Typography className={classes.namesTBA} variant="h4">
                  To be announced
                </Typography>
              </Grid>
            </>
          }
          
        </Grid>
      </Grid>
    </Box>
  </Card>
)};

const query = graphql`
  {
    allFile(filter: {sourceInstanceName: {eq: "crab-assets"}}) {
      nodes {
        publicURL
        name
      }
    }
    allCrabListJson {
      nodes {
        jsonId
        name
        fullname
      }
    }
    allCardImg: allFile(filter: {sourceInstanceName: {eq: "crab-cards"}}) {
      nodes {
        name
        publicURL
      }
    }
  }
`

export default CrabList;
